
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');


html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.artikel-container {
  margin-top: 150px;
}



@import url(bootstrap.min.css);

.navbar {
  transition: 100ms;
}

.active {
  border-bottom: 2px white solid;
  transition: 500ms;
}

.navbar-darkk {
  background-color: rgb(39,128,227,0.8) !important;
  backdrop-filter: blur(2px);
  color: white !important;
}



.secondBg {
  background-color: #F8F9FA;
}

.containerCard {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.containerCard .box {
  position: relative;
  width: 30rem;
  height: 30rem;
  margin: 4rem;
}



.containerCard .box:hover .imgBox {
  transform: translate(-3.5rem, -3.5rem);
}

.containerCard .box:hover .content {
  transform: translate(3.5rem, 3.5rem);
}

.imgBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: all 0.5s ease-in-out;
}

.imgBox img {
  width: 30rem;
  height: 30rem;
  object-fit: cover;
  resize: both;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  background-color: #fff;
  z-index: 1;
  align-items: flex-end;
  text-align: center;
  transition: 0.5s ease-in-out;
}

.content h2 {
  display: block;
  font-size: 2rem;
  color: #111;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 1px;
}

.content span {
  color: #555;
  font-size: 1.4rem;
  font-weight: 300;
  letter-spacing: 2px;
}

.text-white-footer {
  cursor: pointer;
  color: white;
  font-weight: bold;
}

.text-white-footer:hover {
  color: rgb(39,128,227) !important;
}

.bg-kuning {
  background-color: #ffff00;
}

.card {
  background-color: transparent;
  border: none;
}

.medsos {
  transition: 500ms;
}

.medsos:hover {
  opacity: 0.8;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}


.keAtas {
  position: relative;
  z-index: 10;
  margin-top: -7px;
  border-top: none !important;
  box-shadow: none !important;
}

.adminContainer {
  overflow-x: scroll;
}


.carouselMobile {
  display: none;
}

.produkPointer {
  cursor: pointer !important;
}





.topnavActive {
  background-color: #ffff00;
  border-top: 2px rgb(39, 128, 227) solid;
  z-index: -99;
  padding: 15px 15px 5px 15px;
  color: black !important;
  cursor: pointer;
}
.topnav {
  background-color: #ffff00;
  z-index: -99;
  padding: 15px 15px 0px 15px;
  color: black !important;
  margin-bottom: 40px !important;
  cursor: pointer;
}



.kontakContainer {
  position: relative;
}







.select-search {
  width: 100% !important;
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  box-sizing: border-box;
  color: black !important;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
  color: #000;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
* Input
*/
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
  list-style: none;
  padding: 0;
}


/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #2780e3 !important;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(80, 164, 212, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #5591d6 !important;
  color: #fff;
}

/**
* Group
*/
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple)
  .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #5c9ae0 !important;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}


@media (max-width: 767px) {
  .carouselPc {
    display: none;
  }

  .carouselMobile {
    display: flex;
  }
  .select-search__row {
    min-height: 80px;
  }
  .select-search__option.is-selected {
    background: #2780e3 !important;
    height: 80px;
    color: #fff;
  }

  .select-search__option.is-highlighted,
  .select-search__option:not(.is-selected):hover {
    background: rgba(47, 204, 139, 0.1);
    height: 80px;
  }
  .select-search__option.is-highlighted.is-selected,
  .select-search__option.is-selected:hover {
    background: #5591d6 !important;
    height: 80px;
    color: #fff;
  }
}

@media (max-width: 305px) {
  .active {
    border: none;
  }
  body {
    width: 100vh;
  }

  .select-search__row {
    min-height: 80px;
  }
  .select-search__option.is-selected {
    background: #2780e3 !important;
    height: 80px;
    color: #fff;
  }

  .select-search__option.is-highlighted,
  .select-search__option:not(.is-selected):hover {
    background: rgba(47, 204, 139, 0.1);
    height: 80px;
  }
  .select-search__option.is-highlighted.is-selected,
  .select-search__option.is-selected:hover {
    background: #5591d6 !important;
    height: 80px;
    color: #fff;
  }
}